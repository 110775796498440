@import url('https://fonts.googleapis.com/css?family=Roboto+Mono');

.bg {
    /* The image used */
  background-image: url(./img/bg1.jpg);
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    z-index:1000;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  color: aliceblue;

  overflow-y: scroll;
}

html {
  overflow: hidden;
  height: 100%;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;
}
body {
  /* overflow: auto; */
  height: 100%;
  font-family: 'Roboto Mono';
}