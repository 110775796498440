.scroll-container {
    margin-left: calc(150px + 8vw);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scroll-container::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;
}

.scroll-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; 
}



.scroll-row {
    margin-top: 150px;
}