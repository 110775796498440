@import url(https://fonts.googleapis.com/css?family=Roboto+Mono);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg {
    /* The image used */
  background-image: url(/static/media/bg1.7bf26565.jpg);
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    z-index:1000;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  color: aliceblue;

  overflow-y: scroll;
}

html {
  overflow: hidden;
  height: 100%;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;
}
body {
  /* overflow: auto; */
  height: 100%;
  font-family: 'Roboto Mono';
}
.scroll-container {
    margin-left: calc(150px + 8vw);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    overflow-y: scroll;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scroll-container::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;
}

.scroll-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; 
}



.scroll-row {
    margin-top: 150px;
}
.side-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(150px + 5vw) ;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.logo-image {
    width: 80px;
    margin-top: 20%;
    margin-left: 10%;
    margin-bottom: 25px;
}

.menu-item {
    font-size: calc(11px + 1vw);
    font-weight: 500;
    margin-left: 15%;
    margin-top: 10px;
    margin-bottom: 0px;
    color: aliceblue;
    text-decoration: none;

}

.menu-item-icon {
    margin-right: 5px;
}


.bottom-text {
    font-size: calc(10px + 0.2vw);
    position: fixed;
    bottom: 50px;
    width: calc(150px + 3vw);
    margin-left: calc(20px + 1vw);
    font-weight: 300;
   
}

.contact {
    font-size: calc(10px + 0.2vw);
    color: aliceblue;
    position: fixed;
    bottom: 25px;
    width: calc(150px + 3vw);
    margin-left: calc(20px + 1vw);
    font-weight: 300;
}


a:link {
    color: aliceblue
}
  
  /* visited link */
a:visited {
    color: aliceblue;
}
  
  /* mouse over link */
  a:hover {
    color: #cad7e3;
  }
  
  /* selected link */
  a:active {
    color: aliceblue;
  }
